.home {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
    color: #3e497a;
  }
  
  .about {
    width: 100%;
    height: auto; /* Change height to auto */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #21325e;
    color: #f0f0f0;
    padding: 20px 0 20px 0; /* Add padding to create space around content */
  }
  
  .about h2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 70px;
    color: #e9d35b;
    margin: 0; /* Remove height and add margin for better spacing */
  }

  .about img {
    margin-top: 20px;
  }
  
  .about .prompt {
    width: 80%; /* Adjust the width based on your design */
    margin-top: 20px; /* Add margin-top to create space between heading and prompt */
    font-size: 30px;
  }
  
  .prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
  }
  
  
  .skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .skills h1 {
    color: #191842;
    margin-bottom: 0;
  }
  
  .skills .list {
    list-style: none;
    width: 60%;
    margin-top: 20px;
  }
  
  .list span {
    font-size: 20px;
  }
  
  @media only screen and (max-width: 600px) {
    .about h2 {
      font-size: 40px;
    }
  
    .about .prompt {
      margin-top: 10px;
      font-size: 20px;
      width: 80%;
    }
  
    .skills {
      text-align: center;
    }
    .list {
      padding: 0;
    }
  
    .skills h2 {
      font-size: 30px;
    }
  }